<template>
  <div>
    <div class="tmp"></div>
    <v-card>
      <v-card-title class="justify-center">
        404 Not found
      </v-card-title>
    </v-card>
  </div>
</template>
 
<script>
export default {
}
</script>
<style scoped>
.tmp {
  height: 4.09rem;
}
</style>